import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { graphql, Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './relic-sets.scss';

import lodash from 'lodash';
import { IHSRRelic } from '../../../modules/common/model/graphql-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterBar } from '../../../modules/cs/common/components/filter-bar/filter-bar';
import { HSRRelicSet } from '../../../modules/hsr/common/components/hsr-relic-set';
import { Alert, Col, Row } from 'react-bootstrap';

interface IHSRCharacterNodes {
  nodes: IHSRRelic[];
}

interface IHSRCharacterEntry {
  allCharacters: IHSRCharacterNodes;
}

interface IProps {
  data: IHSRCharacterEntry;
}

const HSRDBRelicsPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search relics...'
      },
      {
        key: 'type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'Relic Set', value: 'Relic Set' },
          { label: 'Planetary Ornament Set', value: 'Planetary Ornament Set' }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'relicId');
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.indexOf(activeFilters.type) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke'}
      game="hsr"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Relic Sets</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_relics.png"
            alt="Relic Sets"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Relic Sets</h1>
          <h2>List of available Relic Sets in Honkai: Star Rail.</h2>
          <p>
            Last updated: <strong>19/02/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Relic Sets"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} Relic Set
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar hsr">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <Alert variant="primary" className="small">
        <p>
          This guide only contains a list of available Relic Sets. If you want
          to learn more about the Relics themselves or the sub-stats you can
          roll on them, check our{' '}
          <Link to="/star-rail/guides/relic-stats">Relic Stats guide</Link>.
        </p>
      </Alert>
      <Row xs={1} xxl={2} className="relic-set-container">
        {visibleCharacters.map((emp, index) => {
          return (
            <Col key={index}>
              <HSRRelicSet name={emp.name} />
            </Col>
          );
        })}
      </Row>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../../images/starrail/no_results.png"
            alt="No results"
          />
          <p>No Relic set found. Try changing your filter.</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default HSRDBRelicsPage;

export const Head: React.FC = () => (
  <Seo
    title="Relic Sets | Honkai: Star Rail | Prydwen Institute"
    description="List of available Relic Sets in Honkai: Star Rail."
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulHsrRelics(sort: { fields: name, order: ASC }) {
      nodes {
        ...HsrRelicsFieldsFragment
      }
    }
  }
`;
